import { PageResponse } from '@/builders/pageResolver';
import { homePageBuilder } from '@/builders/pages';
import Layout from '@/components/ui/Layout/Layout';
import Page from '@/components/ui/Page/Page';
import { addCacheHeaders } from '@/utils/cache';
import { InferGetServerSidePropsType } from 'next';

function HomePage(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
  return (
    <Layout useTransparentHeader={props.useTransparentHeader}>
      <Page page={props} />
    </Layout>
  );
}

export default HomePage;

export const getServerSideProps = async ({ res }) => {
  const page: PageResponse = await homePageBuilder();

  addCacheHeaders(res, page);

  return {
    props: {
      ...page,
    },
  };
};
